import React from 'react';

import {FormSelect} from "react-bootstrap";

import "./TimeSelect.css"

interface Props {
    hour: string,
    setHour: (arg0: string) => void,
    minute: string,
    setMinute: (arg0: string) => void,
    AmOrPm: string,
    setAmOrPm: (arg0: string) => void,
}

const TimeSelect: React.FC<Props> = (props:Props) => {
    return (
        <div className={"time-select"}>
            <FormSelect
                className={"form-select time-select-select"}
                value={props.hour}
                onChange={(event) => {
                    props.setHour(event.currentTarget.value);
                }}
            >
                <option label={'12'} value={'12'}/>
                <option label={'01'} value={'01'}/>
                <option label={'02'} value={'02'}/>
                <option label={'03'} value={'03'}/>
                <option label={'04'} value={'04'}/>
                <option label={'05'} value={'05'}/>
                <option label={'06'} value={'06'}/>
                <option label={'07'} value={'07'}/>
                <option label={'08'} value={'08'}/>
                <option label={'09'} value={'09'}/>
                <option label={'10'} value={'10'}/>
                <option label={'11'} value={'11'}/>
            </FormSelect>
            <FormSelect
                className={"form-select time-select-select"}
                value={props.minute}
                onChange={(event) => {
                    props.setMinute(event.currentTarget.value);
                }}
            >
                <option label={'00'} value={'00'}/>
                <option label={'15'} value={'15'}/>
                <option label={'30'} value={'30'}/>
                <option label={'45'} value={'45'}/>
            </FormSelect>
            <FormSelect
                className={"form-select time-select-select"}
                value={props.AmOrPm}
                onChange={(event) => {
                    props.setAmOrPm(event.currentTarget.value);
                }}
            >
                <option label={'AM'} value={'AM'}/>
                <option label={'PM'} value={'PM'}/>
            </FormSelect>
        </div>
    );
}

export default TimeSelect;
