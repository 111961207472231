import React, {useEffect, useState} from "react";
import {Card, FormSelect} from "react-bootstrap";
import {Location, useTimeslotsApi} from "../hooks/useTimeslotsApi";
import {Switch, Route, Link} from "react-router-dom";
import PaymentMethodsEditor from "../components/PaymentMethodsEditor";
import ScheduleEditor from "../components/ScheduleEditor/ScheduleEditor";

import "./VendorPage.css"
import PageHeader from "../components/PageHeader";

const VendorPage: React.FC = () => {

    const {getLocations} = useTimeslotsApi();

    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);

    const [selectedLocationName, setSelectedLocationName] =
        useState<string>("");

    // Gets list of Locations when page loads.
    useEffect(() => {
        if(locations.length === 0) {
            getLocations().then((locations) => {
                if(locations !== null) {
                    setLocations(locations);
                }
            })
        }
    }, [locations.length])

    useEffect(() => {
        if(selectedLocation !== 0) {
            locations.forEach(location => {
                if(location.ID === selectedLocation) {
                    setSelectedLocationName(location.Name);
                }
            })
        }
    },[selectedLocation])

    return (
        <div className={"app-page"}>
            <PageHeader pageTitle={""}/>
            <Card className={"vt-card-vertical-list"}>
                <Card.Header>Location</Card.Header>
                <Card.Body>
                    <FormSelect
                        value={selectedLocation}
                        onChange={(event) => {
                            setSelectedLocation(parseInt(event.currentTarget.value));
                        }}
                    >
                        <option key={"0"} label={"Select a Location"} value={0}/>
                        {locations.map((value) => {
                            return <option key={value.ID.toString()} label={value.Name} value={value.ID}/>
                        })}
                    </FormSelect>
                </Card.Body>
            </Card>
            {selectedLocation !== 0 &&
                <div>
                    <div className={"link-div"}>
                        <Link className={"editor-link"} to={"/vendor/schedule"}>Schedule</Link>
                        <Link className={"editor-link"} to={"/vendor/payment-methods"}>Payment Methods</Link>
                    </div>
                    <Switch>
                        <Route path={"/vendor/schedule"}>
                            <ScheduleEditor LocationID={selectedLocation}/>
                        </Route>
                        <Route path={"/vendor/payment-methods"}>
                            <PaymentMethodsEditor LocationName={selectedLocationName}/>
                        </Route>
                    </Switch>
                </div>
            }
        </div>
    );
}

export default VendorPage;
