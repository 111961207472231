import React from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import VendorPage from "./pages/VendorPage";

function App() {
    return (
        <Router>
            <div className={"app-div"}>
                <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
                    integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
                    crossOrigin="anonymous"
                />
                <Switch>
                    <Route path={"/vendor"}>
                        <VendorPage/>
                    </Route>
                    <Route path={"/"}>
                        <LoginPage/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
