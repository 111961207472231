import {useState} from 'react';


export interface ErrorMessage {
    key: string;
    status_code: string | null;
    message: string;
}

export function useErrorHandling() {
    const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);

    /**
     * Adds a new Error Message to the list.
     * @param key - A key to uniquely identify the error message.
     * @param message - The text of the error message.
     * @param status_code - The Status Code of the message. Used only if the
     *                      error was from a REST API request.
     */
    const addErrorMessage = (key: string, message: string,
                             status_code: string | null = null) => {
        let newErrorMessages: ErrorMessage[] = errorMessages.slice();

        let newErrorHasUniqueKey: boolean = true;
        newErrorMessages.forEach((error) => {
            if (key === error.key) {
                newErrorHasUniqueKey = false;
            }
        });

        if (newErrorHasUniqueKey) {
            newErrorMessages.push({
                key: key,
                status_code: status_code,
                message: message,
            });
            setErrorMessages(newErrorMessages);
        }
    };

    /**
     * Add multiple error messages.
     *
     * @param newMessages - An array of Error Messages to add.
     */
    const addErrorMessages = (newMessages: ErrorMessage[]) => {
        let newErrorMessages: ErrorMessage[] = errorMessages.slice();

        newMessages.forEach((newError) => {
            let newMessageHasUniqueKey: boolean = true;
            errorMessages.forEach((error) => {
                if(error.key === newError.key) {
                    newMessageHasUniqueKey = false;
                }
            });

            if(newMessageHasUniqueKey) {
                newErrorMessages.push(newError);
            }
        });

        setErrorMessages(newErrorMessages);
    }

    /**
     * Replace the current list of Error Messages with a new one.
     *
     * @param newMessages - An array of Error Messages to replace the existing one.
     */
    const replaceErrorMessages = (newMessages: ErrorMessage[]) => {
        setErrorMessages(newMessages);
    }

    /**
     * Removes a specific error message from the list.
     *
     * In the event that the key is not present in the list, this function
     * simply does nothing.
     *
     * @param key - The key of the message to remove.
     */
    const removeErrorMessage = (key: string) => {
        let newErrorMessages: ErrorMessage[] = errorMessages.slice();

        errorMessages.forEach((error, index) => {
            if (key === error.key) {
                newErrorMessages.splice(index, 1);
            }
        });

        setErrorMessages(newErrorMessages);
    };


    /**
     * Removes all error messages from the list.
     */
    const clearErrorMessages = () => {
        setErrorMessages([]);
    };

    /**
     * Determines whether a request to a REST API returned successfully or not.
     *
     * If the request is OK, this function returns the request. Otherwise, it
     * throws an Error.
     *
     * @param response - The Response object to check.
     */
    const checkForResponseErrors = async (response: Response): Promise<Response> => {
        if(!response.ok) {
            throw Error(response.status.toString() + " " + response.statusText);
        }
        return response;
    };

    return {
        errorMessages,
        addErrorMessage,
        addErrorMessages,
        replaceErrorMessages,
        removeErrorMessage,
        clearErrorMessages,
        checkForResponseErrors,
    };

}
