import {Timeslot, useTimeslotsApi} from "../../hooks/useTimeslotsApi";
import {ErrorMessage, useErrorHandling} from "../../hooks/useErrorHandling";
import React, {useEffect, useState} from "react";
import TimeSelect from "./TimeSelect";

import "../../Style.css"
import "./DayView.css"
import {Button, Card, FormSelect} from "react-bootstrap";
import NumericInput from "../NumericInput";
import TimeslotList from "./TimeslotList";
import ErrorDisplay from "../ErrorDisplay";

export interface DayViewFormData {
    openHour: string,
    openMinutes: string,
    openAmOrPm: string,
    closeHour: string,
    closeMinutes: string,
    closeAmOrPm: string,
    timeslotSize: string,
    leadTime: string,
    orderCap: string,
}

interface Props {
    day: string,
    index: number,
    timeslots: Timeslot[],
    setTimeslots: (arg0: Timeslot[], arg1: number) => void,
    serviceTypeId: number,
    formData: DayViewFormData | null,
    copyDownFormData: (arg0: DayViewFormData, arg1: number) => void,
}

const DayView: React.FC<Props> = (props: Props) => {

    const {generateTimeslots} = useTimeslotsApi();
    const {errorMessages, replaceErrorMessages, clearErrorMessages} = useErrorHandling();

    // Values from Form Fields.
    const [openHour, setOpenHour] = useState<string>("12");
    const [openMinutes, setOpenMinutes] = useState<string>("00");
    const [openAmOrPm, setOpenAmOrPm] = useState<string>("AM");
    const [closeHour, setCloseHour] = useState<string>("12");
    const [closeMinutes, setCloseMinutes] = useState<string>("00");
    const [closeAmOrPm, setCloseAmOrPm] = useState<string>("AM");
    const [timeslotSize, setTimeslotSize] = useState<string>("0");
    const [leadTime, setLeadTime] = useState<string>("");
    const [orderCap, setOrderCap] = useState<string>("");

    useEffect(() => {
        if(props.formData !== null) {
            setOpenHour(props.formData.openHour);
            setOpenMinutes(props.formData.openMinutes);
            setOpenAmOrPm(props.formData.openAmOrPm);
            setCloseHour(props.formData.closeHour);
            setCloseMinutes(props.formData.closeMinutes);
            setCloseAmOrPm(props.formData.openAmOrPm);
            setTimeslotSize(props.formData.timeslotSize);
            setLeadTime(props.formData.leadTime);
            setOrderCap(props.formData.orderCap);
        }
        else if(props.timeslots.length > 0) {
            // Set Open Time.
            let openHour = props.timeslots[0].StartTime.hours;
            if(openHour === 0) {
                setOpenHour("12");
                setOpenAmOrPm("AM");
            }
            else if(openHour < 10) {
                setOpenHour("0" + openHour.toString());
                setOpenAmOrPm("AM");
            }
            else if(openHour < 12) {
                setOpenHour(openHour.toString());
                setOpenAmOrPm("AM");
            }
            else if(openHour === 12) {
                setOpenHour("12");
                setOpenAmOrPm("PM");
            }
            else if(openHour < 22) {
                setOpenHour("0" + (openHour - 12).toString());
                setOpenAmOrPm("PM");
            }
            else {
                setOpenHour((openHour - 12).toString());
                setOpenAmOrPm("PM");
            }
            setOpenMinutes(props.timeslots[0].StartTime.minutes.toString());

            // Set Close Time
            let closeHour = props.timeslots[props.timeslots.length - 1].EndTime.hours;
            let closeMinutes = (props.timeslots[props.timeslots.length - 1].EndTime.minutes + 1) % 60;
            if(closeMinutes === 0) {
                closeHour = (closeHour + 1) % 24;
            }

            if(closeHour === 0) {
                setCloseHour("12");
                setCloseAmOrPm("AM");
            }
            else if(closeHour < 10) {
                setCloseHour("0" + closeHour.toString());
                setCloseAmOrPm("AM");
            }
            else if(closeHour < 12) {
                setCloseHour(closeHour.toString());
                setCloseAmOrPm("AM");
            }
            else if(closeHour === 12) {
                setCloseHour(closeHour.toString());
                setCloseAmOrPm("PM");
            }
            else if(closeHour < 22) {
                setCloseHour("0" + (closeHour - 12).toString());
                setCloseAmOrPm("PM");
            }
            else {
                setCloseHour((closeHour - 12).toString());
                setCloseAmOrPm("PM");
            }

            // Set Timeslot Size.
            let minutes0: number = props.timeslots[0].StartTime.hours * 60 +
                props.timeslots[0].StartTime.minutes;
            let minutes1: number = props.timeslots[1].StartTime.hours * 60 +
                props.timeslots[1].StartTime.minutes;
            setTimeslotSize((minutes1 - minutes0).toString());

            // Set Order Cap.
            let orderCaps: number[] = [];
            props.timeslots.forEach(timeslot => {
                if(!orderCaps.includes(timeslot.OrderCap)) {
                    orderCaps.push(timeslot.OrderCap);
                }
            });

            if(orderCaps.length === 1) {
                setOrderCap(orderCaps[0].toString());
            }

            // Set Lead Time.
            let leadTimes: number[] = [];
            props.timeslots.forEach(timeslot => {
                if(!leadTimes.includes(timeslot.LeadTimeMinutes)) {
                    leadTimes.push(timeslot.LeadTimeMinutes);
                }
            });

            if(leadTimes.length === 1) {
                setLeadTime(leadTimes[0].toString());
            }
        }
    }, [props.formData])

    function createNewTimeslots() {

        // Convert displayed "Open Time" to 24-hr format.
        let currentHour = parseInt(openHour, 10);
        let currentMinute = parseInt(openMinutes, 10);
        if( currentHour === 12 && openAmOrPm === "AM") {
            currentHour = 0;
        }
        if (openAmOrPm === "PM" && currentHour !== 12) {
            currentHour += 12;
        }

        // Convert displayed "Close Time" to 24-hr format.
        let endHour = parseInt(closeHour, 10);
        let endMinute = parseInt(closeMinutes, 10);
        if( endHour === 12 && closeAmOrPm === "AM") {
            endHour = 0;
        }
        if (closeAmOrPm === "PM" && currentHour !== 12) {
            endHour += 12;
        }

        const timeslotSizeNum = parseInt(timeslotSize, 10);
        const leadTimeNum = parseInt(leadTime, 10);
        const orderCapNum = parseInt(orderCap, 10);

        let formsAreValid: boolean = true;
        let formErrorMessages: ErrorMessage[] = [];

        if(timeslotSizeNum === 0) {
            formsAreValid = false;
            formErrorMessages.push({
                key: "BLANK_TIMESLOT_SIZE",
                message: "Timeslot Size needs to be selected.",
                status_code: null,
            })
        }
        if(isNaN(leadTimeNum)) {
            formsAreValid = false;
            formErrorMessages.push({
                key: "BLANK_LEAD_TIME",
                message: "Lead Time cannot be blank.",
                status_code: null,
            })
        }
        if(isNaN(orderCapNum)) {
            formsAreValid = false;
            formErrorMessages.push({
                key: "BLANK_ORDER_CAP",
                message: "Order Cap cannot be blank.",
                status_code: null,
            })
        }

        if(formsAreValid) {
            let newTimeslots = generateTimeslots(currentHour, currentMinute,
                endHour, endMinute, timeslotSizeNum, leadTimeNum,
                props.serviceTypeId, orderCapNum, props.day);

            props.setTimeslots(newTimeslots, props.index);
        } else {
            replaceErrorMessages(formErrorMessages);
        }
    }

    function clearTimeslots() {
        props.setTimeslots([], props.index);
        setOpenHour("12");
        setOpenMinutes("00");
        setOpenAmOrPm("AM");
        setCloseHour("12");
        setCloseMinutes("00");
        setCloseAmOrPm("AM");
        setTimeslotSize("0");
        setLeadTime("");
        setOrderCap("");
    }

    function setTimeslotsForDay(updatedTimeslots: Timeslot[]) {
        props.setTimeslots(updatedTimeslots, props.index);
    }

    function copyDown() {
        let formData: DayViewFormData = {
            openHour: openHour,
            openMinutes: openMinutes,
            openAmOrPm: openAmOrPm,
            closeHour: closeHour,
            closeMinutes: closeMinutes,
            closeAmOrPm: closeAmOrPm,
            timeslotSize: timeslotSize,
            leadTime: leadTime,
            orderCap: orderCap,
        }
        props.copyDownFormData(formData, props.index);
    }

    return (
        <Card className={"vt-card-vertical-list"}>
            <Card.Header>{props.day}</Card.Header>
            <Card.Body>
                <div className={"day-view"}>
                    <TimeSelect hour={openHour} setHour={setOpenHour}
                                minute={openMinutes} setMinute={setOpenMinutes}
                                AmOrPm={openAmOrPm} setAmOrPm={setOpenAmOrPm}
                    />
                    <p> To </p>
                    <TimeSelect hour={closeHour} setHour={setCloseHour}
                                minute={closeMinutes} setMinute={setCloseMinutes}
                                AmOrPm={closeAmOrPm} setAmOrPm={setCloseAmOrPm}
                    />
                    <FormSelect
                        value={timeslotSize}
                        className={"timeslot-size-picker"}
                        placeholder={"Timeslot Size"}
                        onChange={(event) => {
                            setTimeslotSize(event.currentTarget.value);
                        }}
                    >
                        <option label={"Timeslot Size"} value={'0'}/>
                        <option label={"15 mins."} value={'15'}/>
                        <option label={"30 mins."} value={'30'}/>
                        <option label={"1 hr."} value={'60'}/>
                    </FormSelect>
                    <NumericInput setValue={setLeadTime} value={leadTime} placeholder={"Lead Time"}/>
                    <NumericInput setValue={setOrderCap} value={orderCap} placeholder={"Order Cap"}/>
                    <Button className={"day-view-button"} variant={"primary"} onClick={createNewTimeslots}>
                        Create Slots
                    </Button>
                    <Button className={"day-view-button"} variant={"danger"} onClick={clearTimeslots}>
                        Clear Slots
                    </Button>
                    <Button className={"day-view-button"} variant={"primary"} onClick={copyDown}>
                        Copy Down Slots
                    </Button>
                </div>
                <TimeslotList timeslots={props.timeslots} setTimeslots={setTimeslotsForDay}/>
                <ErrorDisplay errors={errorMessages} clearErrors={clearErrorMessages}/>
            </Card.Body>
        </Card>
    )
}

export default DayView
