import React, {useEffect, useState} from "react";
import {Card, FormCheck} from "react-bootstrap";
import {PaymentMethod, usePaymentMethodsApi} from "../hooks/usePaymentMethodsApi";
import Spinner from "react-bootstrap/Spinner";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";

interface Props {
    LocationName: string
}

const PaymentMethodsEditor: React.FC<Props> = (props: Props) => {

    const {getSiteEnabledPaymentMethods, getVendorId,
    getVendorPaymentMethods, addPaymentMethodToVendor,
    removePaymentMethodFromVendor} = usePaymentMethodsApi();

    const [sitePaymentMethods, setSitePaymentMethods] =
        useState<PaymentMethod[]>([]);
    const [vendorId, setVendorId] = useState<number>(-1);

    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

    useEffect(() => {
        setShowLoadingSpinner(true);
        getSiteEnabledPaymentMethods().then((methods: PaymentMethod[] | null) => {
            if(methods !== null) {
                getVendorId(props.LocationName).then(vendorId => {
                    if(vendorId !== null) {
                        setVendorId(vendorId);
                        getVendorPaymentMethods(vendorId).then(vendorMethods => {
                            if(vendorMethods !== null) {
                                methods.forEach(method => {
                                    if(vendorMethods.includes(method.id)) {
                                        method.isVendorEnabled = true;
                                    }
                                });
                                setSitePaymentMethods(methods);
                                setShowLoadingSpinner(false);
                            }
                        });
                    }
                });
            }
        });
    }, [props.LocationName]);

    function updatePaymentMethod(paymentMethodID: string, isActive: boolean) {
        if(isActive) {
            addPaymentMethodToVendor(vendorId, paymentMethodID).then();
        } else {
            removePaymentMethodFromVendor(vendorId, paymentMethodID).then();
        }
    }

    return (
        <div id={"payment-methods-editor"}>
            {showLoadingSpinner &&
                <div className={"centered-spinner-div"}>
                    <Spinner variant={"primary"} animation={"border"}/>
                </div>
            }

            {!showLoadingSpinner &&
                <Card>
                    <Card.Header>Payment Methods</Card.Header>
                    <Card.Body>
                        {sitePaymentMethods.map(method => {
                            return <div
                                className={"row-form-div"}
                                key={method.id}>
                                <FormCheck
                                    defaultChecked={method.isVendorEnabled}
                                    type={"switch"}
                                    onChange={event => {
                                        updatePaymentMethod(method.id, event.currentTarget.checked);
                                    }}
                                />
                                <FormCheckLabel>{method.title}</FormCheckLabel>
                            </div>
                        })}
                    </Card.Body>
                </Card>
            }
        </div>
    );
}

export default PaymentMethodsEditor;
