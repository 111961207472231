import React, {useEffect, useState} from 'react';

import {Timeslot} from "../../hooks/useTimeslotsApi";
import NumericInput from "../NumericInput";
import FormCheck from "react-bootstrap/FormCheckInput";

//TODO: Review CSS. Find better way to specify checkbox and paragraph width.
import "./TimeslotItem.css"

interface Props {
    index: number
    timeslot: Timeslot
    updateTimeslot: (arg0: Timeslot, arg1: number) => void
}

const TimeslotItem: React.FC<Props> = (props:Props) => {

    const [startHours, setStartHours] = useState<string>("");
    const [startMinutes, setStartMinutes] = useState<string>("");
    const [startAmOrPm, setStartAmOrPm] = useState<string>("AM");
    const [endHours, setEndHours] = useState<string>("");
    const [endMinutes, setEndMinutes] = useState<string>("");
    const [endAmOrPm, setEndAmOrPm] = useState<string>("AM");

    // Format the display Time.
    useEffect(() => {
        let startHourNum = props.timeslot.StartTime.hours;
        if (startHourNum === 0) {
            setStartHours("12");
        } else {
            if(startHourNum === 12) {
                setStartAmOrPm("PM");
            }
            if (startHourNum > 12) {
                setStartAmOrPm("PM");
                startHourNum -= 12;
            }
            if (startHourNum < 10) {
                setStartHours('0' + startHourNum.toString());
            } else {
                setStartHours(startHourNum.toString());
            }
        }
        if(props.timeslot.StartTime.minutes === 0) {
            setStartMinutes("00");
        } else {
            setStartMinutes(props.timeslot.StartTime.minutes.toString());
        }

        let endHourNum = props.timeslot.EndTime.hours;
        if (endHourNum === 0) {
            setEndHours("12");
        } else {
            if(endHourNum === 12) {
                setEndAmOrPm("PM");
            }
            if (endHourNum > 12) {
                setEndAmOrPm("PM");
                endHourNum -= 12;
            }
            if (endHourNum < 10) {
                setEndHours('0' + endHourNum.toString());
            } else {
                setEndHours(endHourNum.toString());
            }
        }
        if(props.timeslot.EndTime.minutes === 0) {
            setEndMinutes("00");
        } else {
            setEndMinutes(props.timeslot.EndTime.minutes.toString());
        }

    }, [props.timeslot.StartTime, props.timeslot.EndTime])

    function setPublished(isPublished: boolean) {
        let updatedTimeslot = props.timeslot;
        updatedTimeslot.Published = isPublished;
        props.updateTimeslot(updatedTimeslot, props.index);
    }

    function setLeadTime(value:  string) {
        let updatedTimeslot = props.timeslot;
        updatedTimeslot.LeadTimeMinutes = parseInt(value, 10);
        props.updateTimeslot(updatedTimeslot, props.index);
    }

    function setOrderCap(value: string) {
        let updatedTimeslot = props.timeslot;
        updatedTimeslot.OrderCap = parseInt(value, 10);
        props.updateTimeslot(updatedTimeslot, props.index);
    }

    return(
        <div className={"timeslot-item"}>
            <FormCheck
                defaultChecked={props.timeslot.Published}
                className={"checkbox"}
                onChange={(event) => {
                    setPublished(!props.timeslot.Published);
                }}
            />
            <p className={"timeslot-range"}>
                {startHours}:{startMinutes} {startAmOrPm} - {endHours}:{endMinutes} {endAmOrPm}
            </p>
            <NumericInput
                setValue={setLeadTime}
                value={props.timeslot.LeadTimeMinutes.toString()}
                placeholder={"Lead Time"}
            />
            <NumericInput
                setValue={setOrderCap}
                value={props.timeslot.OrderCap.toString()}
                placeholder={"Order Cap"}
            />
        </div>
    );
}

export default TimeslotItem;
