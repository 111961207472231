import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {Container, FormControl, Button, FormSelect, Card} from "react-bootstrap";

import {useAuthentication} from "../hooks/useAuthentication"
import ErrorDisplay from "../components/ErrorDisplay";

import "./LoginPage.css"

const LoginPage: React.FC = () => {

    const {authenticateUser, checkCredentials, setSiteUrl, fetchSiteUrl,
        authErrorMessages, authClearErrorMessages} = useAuthentication();
    let history = useHistory();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [selectedSite, setSelectedSite] = useState<string>("");

    //TODO: De-harcode site URLs.
    const site_list = [
        {site: "Stones Country Meats", URL: "https://stage.stonescountrymeats.com/"},
        {site: "Frosty's Bottle Shop", URL: "https://stage.frostysbottleshop.com/"},
        {site: "Fitzgerald GA Virtual Town", URL: "https://fitzgeraldga.virtualtown.io/"},
    ]

    useEffect(() => {
        setSelectedSite(fetchSiteUrl());
        checkCredentials().then((credentialsAreValid) => {
            if(credentialsAreValid) {
                history.push("/vendor");
            }
        })
    }, [fetchSiteUrl, checkCredentials, history]);

    function changeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function changePassword(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function attemptLogin() {
        authenticateUser(username, password).then((isValid) => {
            if(isValid) {
                history.push("/vendor");
            }
        })
    }

    return (
        <div className={"home-page"}>
            <Card className={"login-card"}>
                <Card.Header>Login</Card.Header>
                <Card.Body>
                    <FormControl
                        className={"input"}
                        type={"text"}
                        value={username}
                        placeholder={"username"}
                        onChange={changeUsername}
                    />
                    <FormControl
                        className={"input"}
                        type={"password"}
                        value={password}
                        placeholder={"Password"}
                        onChange={changePassword}
                    />
                    <FormSelect
                        className={"input"}
                        value={selectedSite}
                        onChange={(event) => {
                            setSelectedSite(event.currentTarget.value);
                            setSiteUrl(event.currentTarget.value);
                        }}
                    >
                        <option label={"Select a Site"} value={""}/>
                        {site_list.map((value) => {
                            return <option label={value.site} value={value.URL}/>
                        })}

                    </FormSelect>
                    <Button variant={"primary"} onClick={attemptLogin}>
                        Login
                    </Button>
                </Card.Body>
            </Card>
            <ErrorDisplay errors={authErrorMessages}
                clearErrors={authClearErrorMessages}
            />
        </div>
    );
}

export default LoginPage;
