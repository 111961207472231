import React, {useEffect, useState} from 'react';
import {Timeslot} from "../../hooks/useTimeslotsApi";
import TimeslotItem from "./TimeslotItem";
import AccordionBody from "react-bootstrap/AccordionBody";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {Accordion} from "react-bootstrap";

import "./TimeslotList.css";

interface Props {
    timeslots: Timeslot[],
    setTimeslots: (arg0: Timeslot[]) => void,
}

const TimeslotList: React.FC<Props> = (props: Props) => {

    const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(props.timeslots.length > 0) {
            setActiveKey("0");
        } else {
            setActiveKey(undefined);
        }
    }, [props.timeslots])

    function onListClick() {
        if(activeKey === "0") {
            setActiveKey(undefined);
        } else {
            setActiveKey("0");
        }
    }

    function updateTimeslot(timeslot: Timeslot, index: number) {
        let newTimeslots = props.timeslots.slice();
        newTimeslots[index] = timeslot;
        props.setTimeslots(newTimeslots);
    }

    return (
        <Accordion className={"timeslot-list"} activeKey={activeKey}>
            <AccordionItem eventKey={'0'}>
                <AccordionHeader onClick={onListClick}>Timeslots</AccordionHeader>
                <AccordionBody>
                    {props.timeslots.map((value, index) => {
                        return <TimeslotItem
                            key={index.toString()}
                            index={index} timeslot={value}
                            updateTimeslot={updateTimeslot}
                        />
                    })}
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
}

export default TimeslotList;
