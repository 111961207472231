import React, {useEffect, useState} from "react";
import {FormControl} from "react-bootstrap";

import "./NumericInput.css"

interface Props {
    value: string
    setValue: (arg0: string) => void
    placeholder: string
}

const NumericInput: React.FC<Props> = (props:Props) => {

    const [tempValue, setTempValue] = useState<string>(props.value);

    function isNumeric(str: string) {
        // @ts-ignore
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    useEffect(() => {
        setTempValue(props.value);
    }, [props.value]);

    function updateValue() {
        // @ts-ignore
        if(isNumeric(tempValue) || tempValue === "") {
            props.setValue(tempValue);
        } else {
            setTempValue(props.value);
        }
    }

    return (
        <FormControl
            className={"numeric-input"}
            value={tempValue}
            placeholder={props.placeholder}
            onChange={(event) => {
                setTempValue(event.target.value);
            }}
            onBlur={updateValue}
        />
    );
}

NumericInput.defaultProps = {
    placeholder: "",
}

export default NumericInput;
