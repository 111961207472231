import React, {useEffect, useState} from "react";
import {Accordion, Alert, Button, FormCheck} from "react-bootstrap";
import {LocationService, ServiceType, useTimeslotsApi} from "../../hooks/useTimeslotsApi";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import AccordionItem from "react-bootstrap/AccordionItem";

import "./ServiceTypesMenu.css"
import NumericInput from "../NumericInput";
import Spinner from "react-bootstrap/Spinner";
import {ErrorMessage, useErrorHandling} from "../../hooks/useErrorHandling";
import ErrorDisplay from "../ErrorDisplay";

interface Props {
    locationId: number
    serviceTypesForLocation: LocationService[],
    fetchServiceTypes: (arg0: number) => void,
}

// Tracks the current state of a Service.
interface LocationServiceEditorInfo {
    serviceName: string,
    serviceId: number,
    isActive: boolean,
    minLeadDays: string,
}

/**
 * A React Component for modifying which Services are available to a Location.
 */
const ServiceTypesMenu: React.FC<Props> = (props: Props) => {

    const {getTimeslots, deleteTimeslots, getAllServices, addServiceTypesToLocation,
        removeServicesFromLocation, timeslotErrorMessages,
        timeslotClearErrorMessages} = useTimeslotsApi();

    const [servicesEditorInfo, setServicesEditorInfo] =
        useState<LocationServiceEditorInfo[]>([]);

    //
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
    const [showSubmittingSpinner, setShowSubmittingSpinner] = useState<boolean>(false);
    const [showSubmitSuccess, setShowSubmitSuccess] = useState<boolean>(false);

    // Form Error Messages.
    const {errorMessages, addErrorMessages, clearErrorMessages} = useErrorHandling();

    // Called when the Component is mounted or changes submitted.
    // Used to fetch a list of all services from the Microservice
    // and set initial form values.
    useEffect(() => {
        setShowLoadingSpinner(true);
        getAllServices().then(services => {
            if(services !== null) {
                let newServicesEditorInfo: LocationServiceEditorInfo[] = [];

                services.forEach(service => {
                    let newServiceInfo: LocationServiceEditorInfo = {
                        serviceName: service.Name,
                        serviceId: service.ID,
                        isActive: false,
                        minLeadDays: "",
                    }

                    props.serviceTypesForLocation.forEach(locationService => {
                        if(locationService.Service.Name === service.Name) {
                            newServiceInfo.isActive = true;
                            newServiceInfo.minLeadDays = locationService.MinLeadDays.toString();
                        }
                    });

                    newServicesEditorInfo.push(newServiceInfo);
                });

                setServicesEditorInfo(newServicesEditorInfo);
                setShowLoadingSpinner(false);
            }
        });
    }, [props.serviceTypesForLocation]);

    function finalizeSubmit(formErrorMessages: ErrorMessage[]) {
        if(formErrorMessages.length > 0) {
            addErrorMessages(formErrorMessages);
        }
        else if(timeslotErrorMessages.length === 0) {
            setShowSubmitSuccess(true);
        }
        setShowSubmittingSpinner(false);
        props.fetchServiceTypes(props.locationId);
    }

    function submitChanges() {
        setShowSubmittingSpinner(true);
        let newFormErrors: ErrorMessage[] = [];

        let servicesProcessed: number = 0;
        servicesEditorInfo.forEach((service, index) => {
            let serviceIsSetForLocation = false;
            let serviceMinLeadDaysChanged = false;
            props.serviceTypesForLocation.forEach(locationService => {
                if(service.serviceName === locationService.Service.Name){
                    serviceIsSetForLocation = true;
                    if(parseInt(service.minLeadDays) !==
                        locationService.MinLeadDays) {

                        serviceMinLeadDaysChanged = true;
                    }
                }
            });

            if((!serviceIsSetForLocation || serviceMinLeadDaysChanged)
                && service.isActive) {

                // Add or Update Service.
                if(service.minLeadDays !== "") {
                    addServiceTypesToLocation(props.locationId, service.serviceId,
                        parseInt(service.minLeadDays)).then(success => {
                            servicesProcessed += 1;
                            if(servicesProcessed === servicesEditorInfo.length) {
                                finalizeSubmit(newFormErrors);
                            }
                    });
                }
                else {
                    newFormErrors.push({
                        key: "BLANK_LEAD_DAYS_" + index.toString(),
                        message: "The Min Lead Days value for '" + service.serviceName +
                            "' cannot be blank.",
                        status_code: null,
                    });
                    servicesProcessed += 1;
                    if(servicesProcessed === servicesEditorInfo.length) {
                        finalizeSubmit(newFormErrors);
                    }
                }
            }
            else if(serviceIsSetForLocation && !service.isActive) {
                // Delete Service.
                // TODO: Delete Timeslots for Service.
                getTimeslots(props.locationId, service.serviceId)
                    .then(timeslots => {
                       if(timeslots.length > 0) {
                           let slotIdsToDelete: number[] = [];
                           timeslots.forEach(day => {
                               day.forEach(timeslot => {
                                   slotIdsToDelete.push(timeslot.ID);
                               });
                           });
                           if(slotIdsToDelete.length > 0) {
                               deleteTimeslots(props.locationId, slotIdsToDelete)
                                   .then(success => {
                                       if(success) {
                                           removeServicesFromLocation(props.locationId, service.serviceId)
                                               .then(success => {
                                                   servicesProcessed += 1;
                                                   if(servicesProcessed === servicesEditorInfo.length) {
                                                       finalizeSubmit(newFormErrors);
                                                   }
                                               });
                                       }
                                   });
                           } else {
                               removeServicesFromLocation(props.locationId, service.serviceId)
                                   .then(success => {
                                       servicesProcessed += 1;
                                       if(servicesProcessed === servicesEditorInfo.length) {
                                           finalizeSubmit(newFormErrors);
                                       }
                                   });
                           }
                       }
                    });
            }
            else {
                servicesProcessed += 1;
                if(servicesProcessed === servicesEditorInfo.length) {
                    finalizeSubmit(newFormErrors);
                }
            }
        });
    }

    function updateServiceIsActive(serviceName: string, newValue: boolean) {
        let newServicesEditorInfo = servicesEditorInfo.slice();
        newServicesEditorInfo.forEach(service => {
            if(service.serviceName === serviceName) {
                service.isActive = newValue;
            }
        })
        setServicesEditorInfo(newServicesEditorInfo);
    }

    function updateServiceMinLeadDays(serviceName: string, newValue: string) {
        let newServicesEditorInfo = servicesEditorInfo.slice();
        newServicesEditorInfo.forEach(service => {
            if(service.serviceName === serviceName) {
                service.minLeadDays = newValue;
            }
        })
        setServicesEditorInfo(newServicesEditorInfo);
    }

    return (
        <Accordion>
            <AccordionItem eventKey={"0"}>
                <AccordionHeader>Modify Available Service Types</AccordionHeader>
                <AccordionBody>
                    {showLoadingSpinner &&
                        <div className={"centered-spinner-div"}>
                            <Spinner variant={"primary"} animation={"border"}/>
                        </div>
                    }

                    {!showLoadingSpinner &&
                        <div>
                            {servicesEditorInfo.map(service => {
                                return <div key={service.serviceName + "-editor"}>
                                    <FormCheck
                                        defaultChecked={service.isActive}
                                        onChange={event => {
                                            updateServiceIsActive(service.serviceName,
                                                event.currentTarget.checked);
                                        }}
                                    />
                                    <FormCheckLabel>{service.serviceName}</FormCheckLabel>
                                    <NumericInput
                                        setValue={(newValue) => {
                                            updateServiceMinLeadDays(service.serviceName,
                                                newValue);
                                        }}
                                        value={service.minLeadDays}
                                        placeholder={"Min Lead Days"}
                                    />
                                </div>
                            })}
                            <Button className={"service-type-submit-button"}
                                    onClick={submitChanges}
                            >
                                Submit Changes
                            </Button>
                        </div>
                    }

                    {errorMessages.length > 0 &&
                        <ErrorDisplay
                            errors={errorMessages}
                            clearErrors={clearErrorMessages}
                        />
                    }

                    {timeslotErrorMessages.length > 0 &&
                        <ErrorDisplay
                            errors={timeslotErrorMessages}
                            clearErrors={timeslotClearErrorMessages}
                        />
                    }

                    {showSubmittingSpinner &&
                        <div className={"centered-spinner-div"}>
                            <Spinner variant={"primary"} animation={"border"}/>
                        </div>
                    }

                    {showSubmitSuccess &&
                        <Alert
                            className={"success-alert"}
                            variant={"success"}
                            onClose={() => {
                                setShowSubmitSuccess(false);
                            }}
                            dismissible={true}
                        >
                            <p>Service Type Updates Submitted!</p>
                        </Alert>
                    }
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
}

export default ServiceTypesMenu;
