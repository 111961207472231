import React, {useEffect, useState} from "react";
import {
    Timeslot,
    useTimeslotsApi,
    DaysOfWeekFlags,
    LocationService
} from "../../hooks/useTimeslotsApi";

import DayView from "./DayView";
import {DayViewFormData} from "./DayView";
import ServiceTypesMenu from "./ServiceTypesMenu";
import ErrorDisplay from "../ErrorDisplay";

import {Alert, Button, Card, FormSelect} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import "./ScheduleEditor.css"

interface Props {
    LocationID: number
}

const ScheduleEditor: React.FC<Props> = (props: Props) => {
    const {addUpdateTimeslots, timeslotErrorMessages,
        getTimeslots, deleteTimeslots, getServicesForLocation, timeslotClearErrorMessages} = useTimeslotsApi();

    const [timeslotsForWeek, setTimeSlotsForWeek] = useState<Timeslot[][]>([
        [],[],[],[],[],[],[]
    ]);
    const [modifiedTimeslotsForWeek, setModifiedTimeslotsForWeek] = useState<Timeslot[][]>(
        [[],[],[],[],[],[],[]]
    );
    const [selectedServiceType, setSelectedServiceType] = useState<number>(0);
    const [dayViewFormData, setDayViewFormData] = useState<(DayViewFormData | null)[]>(
        [null, null, null, null, null, null, null]
    );

    // Visibility State
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [showServiceTypesLoading, setShowServiceTypesLoading] = useState<boolean>(false);
    const [showServiceTypes, setShowServiceTypes] = useState<boolean>(false);
    const [showTimeslotsLoading, setShowTimeslotsLoading] = useState<boolean>(false);
    const [showTimeslots, setShowTimeslots] = useState<boolean>(false);
    const [showTimeslotsSubmitting, setShowTimeslotsSubmitting] = useState<boolean>(false);

    const [serviceTypes, setServiceTypes] = useState<LocationService[]>([]);

    /**
     * Used by the DayView component to update changes to a single Day's Timeslots.
     *
     * @param updatedTimeslots
     * @param index
     */
    function setTimeslots(updatedTimeslots: Timeslot[], index: number) {
        let newTimeslotsForWeek = modifiedTimeslotsForWeek.slice();
        newTimeslotsForWeek[index] = updatedTimeslots;
        setModifiedTimeslotsForWeek(newTimeslotsForWeek);
    }

    /**
     * Used by the DayView component to copy data down to 'later' DayView components.
     */
    function copyDownFormAndTimeslots(formData: DayViewFormData, index: number) {

        let newDayViewFormData = dayViewFormData.slice();
        let newModifiedTimeslotsForWeek = modifiedTimeslotsForWeek.slice();
        for(let i = index + 1; i < modifiedTimeslotsForWeek.length; i++) {
            let newTimeslots: Timeslot[] = []

            // Create a non-shallow copy of the Timeslot Array.
            modifiedTimeslotsForWeek[index].forEach(timeslot => {
                newTimeslots.push({
                    DaysOfWeekFlag: DaysOfWeekFlags[i],
                    EndTime: {
                        hours: timeslot.EndTime.hours,
                        minutes: timeslot.EndTime.minutes,
                        seconds: timeslot.EndTime.seconds,
                        nanos: timeslot.EndTime.nanos
                    },
                    ID: 0,
                    LeadTimeMinutes: timeslot.LeadTimeMinutes,
                    OrderCap: timeslot.OrderCap,
                    Published: timeslot.Published,
                    ServiceID: timeslot.ServiceID,
                    StartTime: {
                        hours: timeslot.StartTime.hours,
                        minutes: timeslot.StartTime.minutes,
                        seconds: timeslot.StartTime.seconds,
                        nanos: timeslot.StartTime.nanos
                    }
                })
            })

            newDayViewFormData[i] = formData;
            newModifiedTimeslotsForWeek[i] = newTimeslots;
        }

        setDayViewFormData(newDayViewFormData);
        setModifiedTimeslotsForWeek(newModifiedTimeslotsForWeek);
    }

    function submitTimeslots() {
        setShowTimeslotsSubmitting(true);
        window.scrollTo(0,0);

        let timeslotsToSubmit: Timeslot[] = [];
        let slotIDsToDelete: number[] = [];
        modifiedTimeslotsForWeek.forEach((value, index) => {

            /*
            * Check to see if Microservice Timeslots need to be deleted.
            * True if:
            * 1. The number of modified slots for the day doesn't match the
            *    number of slots on the Microservice.
            * 2. There are modified slots with an ID of 0.
            */
            if((value.length !== timeslotsForWeek[index].length) ||
                ((value.length > 0 && value[0].ID === 0) && timeslotsForWeek[index].length > 0)) {

                timeslotsForWeek[index].forEach(value => {
                    slotIDsToDelete.push(value.ID);
                });
            }

            timeslotsToSubmit = timeslotsToSubmit.concat(value);
        })

        addUpdateTimeslots(props.LocationID, timeslotsToSubmit)
            .then((requestSuccess) => {
                if(requestSuccess) {

                    // Deletes any Timeslots that need to be deleted.
                    // This has to resolve before getting the new Timeslots
                    // or their will be a mix of old and new Timeslots in the
                    // list.
                    deleteTimeslots(props.LocationID, slotIDsToDelete)
                        .then((requestSuccess) => {
                            if(requestSuccess) {

                                // Get the Timeslots back from the Server.
                                // This is so that the Timeslot's IDs stored in
                                // the app correspond with what's on the
                                // Microservice.
                                getTimeslots(props.LocationID, selectedServiceType)
                                    .then((timeslots) => {
                                        setTimeSlotsForWeek(timeslots);
                                        setModifiedTimeslotsForWeek(timeslots);
                                        setShowTimeslotsSubmitting(false);
                                        setShowSuccessAlert(true);
                                    });
                            } else {
                                setShowTimeslotsSubmitting(false);
                            }
                        });
                } else {
                    setShowTimeslotsSubmitting(false);
                }
            });
    }

    useEffect(() => {
        onLocationSelected(props.LocationID);
    }, [props.LocationID])

    function fetchServiceTypes(locationID: number) {
        setSelectedServiceType(0);

        // Clear data for and Hide the Timeslots menu.
        setShowTimeslots(false);
        setTimeSlotsForWeek([[],[],[],[],[],[],[]]);

        getServicesForLocation(locationID).then((services) => {
            if(services !== null) {

                // Populate the "Select a Service Type" Dropdown.
                let serviceTypes: LocationService[] = [];
                services.forEach(value => {
                    if(value.Service.Published) {
                        serviceTypes.push(value);
                    }
                });
                setServiceTypes(serviceTypes);
            }
        });
    }

    function onLocationSelected(value: number) {
        setSelectedServiceType(0);
        if(value === 0) {
            // A Location hasn't been selected.
            // Clear data for and hide the Service Types menu.
            setServiceTypes([]);
            setShowServiceTypes(false);
        } else {
            // Show Spinner while the Services for the location are fetched.
            setShowServiceTypes(false);
            setShowServiceTypesLoading(true);

            getServicesForLocation(value).then((services) => {
                if(services !== null) {

                    // Populate the "Select a Service Type" Dropdown.
                    let serviceTypes: LocationService[] = [];
                    services.forEach(value => {
                        if(value.Service.Published) {
                            serviceTypes.push(value);
                        }
                    });
                    setServiceTypes(serviceTypes);

                    // Switch from spinner to Service Type menu.
                    setShowServiceTypesLoading(false);
                    setShowServiceTypes(true);
                }
            });
        }

        // Clear data for and Hide the Timeslots menu.
        setShowTimeslots(false);
        setTimeSlotsForWeek([[],[],[],[],[],[],[]]);
    }

    function onServiceTypeSelected(value: number) {
        if(value !== 0) {
            setShowTimeslotsLoading(true);
            getTimeslots(props.LocationID, value)
                .then((timeSlots) => {
                    setTimeSlotsForWeek(timeSlots);
                    setModifiedTimeslotsForWeek(timeSlots);
                    setShowTimeslots(true);
                })
        } else {
            setTimeSlotsForWeek([[], [], [], [], [], [], []]);
            setModifiedTimeslotsForWeek([[], [], [], [], [], [], []]);
            setShowTimeslots(false);
        }
        setSelectedServiceType(value);
    }

    // Turns off the Loading Spinner after the TimeSlots have loaded.
    useEffect(() => {
        setShowTimeslotsLoading(false);
    }, [timeslotsForWeek]);

    return (
      <div>
          {showTimeslotsSubmitting &&
              <div className={"centered-spinner-div"}>
                  <Spinner variant={"primary"} animation={"border"}/>
              </div>
          }

          {showSuccessAlert &&
              <Alert
                  className={"success-alert"}
                  variant={"success"}
                  onClose={() => {
                      setShowSuccessAlert(false);
                  }}
                  dismissible={true}
              >
                  <p>Timeslots Submitted!</p>
              </Alert>
          }

          {timeslotErrorMessages.length !== 0 &&
              <ErrorDisplay
                  errors={timeslotErrorMessages}
                  clearErrors={timeslotClearErrorMessages}
              />
          }

          {showServiceTypesLoading &&
          <div className={"centered-spinner-div"}>
              <Spinner variant={"primary"} animation={"border"}/>
          </div>
          }

          {showServiceTypes &&
              <Card className={"vt-card-vertical-list"}>
                  <Card.Header>Service Types</Card.Header>
                  <Card.Body>
                      <FormSelect
                          className={"service-type-select"}
                          value={selectedServiceType}
                          onChange={(event) => {
                              onServiceTypeSelected(parseInt(event.currentTarget.value));
                          }}
                      >
                          <option key={"0"} label={"Select a Service Type"} value={0}/>
                          {serviceTypes.map((value) => {
                              return <option
                                  key={value.Service.ID.toString()}
                                  label={value.Service.Name}
                                  value={value.Service.ID}
                              />
                          })}
                      </FormSelect>
                      <ServiceTypesMenu
                          locationId={props.LocationID}
                          serviceTypesForLocation={serviceTypes}
                          fetchServiceTypes={fetchServiceTypes}
                      />
                  </Card.Body>
              </Card>
          }

          {showTimeslotsLoading &&
              <div className={"centered-spinner-div"}>
                  <Spinner variant={"primary"} animation={"border"}/>
              </div>
          }

          {(showTimeslots && ! showTimeslotsLoading) &&
              <Card className={"vt-card-vertical-list"}>
                  <Card.Header>Timeslots</Card.Header>
                  <Card.Body>
                      <div>
                          {modifiedTimeslotsForWeek.map(((value, index) => {
                              return <DayView
                                  key={DaysOfWeekFlags[index]}
                                  day={DaysOfWeekFlags[index]}
                                  index={index} timeslots={value} setTimeslots={setTimeslots}
                                  serviceTypeId={selectedServiceType}
                                  formData={dayViewFormData[index]}
                                  copyDownFormData={copyDownFormAndTimeslots}
                              />
                          }))}
                      </div>
                      <Button
                          className={"vt-submit-timeslots-button"}
                          onClick={submitTimeslots}
                      >
                          Submit Timeslots
                      </Button>
                  </Card.Body>
              </Card>
          }
      </div>
    );
}

export default ScheduleEditor;
