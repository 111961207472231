import {useAuthentication, AUTH_CREDENTIALS_EXPIRED_KEY,
    AUTH_CREDENTIALS_EXPIRED_MESSAGE} from "./useAuthentication";
import {useErrorHandling} from "./useErrorHandling";
import {useState} from "react";


export interface PaymentMethod {
    id: string,
    title: string,
    isVendorEnabled: boolean,
}

export function usePaymentMethodsApi() {

    const {fetchSiteUrl, fetchCredentials} = useAuthentication();
    const {errorMessages, addErrorMessage, checkForResponseErrors,
        clearErrorMessages} = useErrorHandling();

    async function getSiteEnabledPaymentMethods(): Promise<PaymentMethod[] | null> {
        let promise = new Promise<PaymentMethod[] | null>( async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(null);
            }

            url += "/wp-json/wc/v3/payment_gateways"

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        let returnedPaymentMethods: any[] = myJSON;
                        let newPaymentMethods: PaymentMethod[] = [];
                        returnedPaymentMethods.forEach(method => {
                            if(method.enabled) {
                                newPaymentMethods.push({
                                    id: method.id,
                                    title: method.title,
                                    isVendorEnabled: false,
                                });
                            }
                        });
                        resolve(newPaymentMethods);
                    })
                    .catch(error => {
                        resolve(null);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }
        });

        return await promise;
    }

    async function getVendorId(vendorName: string): Promise<number | null> {
        let promise = new Promise<number | null>( async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(null);
            }

            url += "/wp-json/dokanpaymentmethods/v1/get-vendor-id"

            let post_data = {
                vendor_name: vendorName
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON === -1) {
                            resolve(null)
                        } else {
                            resolve(myJSON);
                        }
                    })
                    .catch(error => {
                        console.log(error.toString());
                        resolve(null);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }
        });

        return await promise;
    }

    async function getVendorPaymentMethods(vendorId: number): Promise<string[] | null> {
        let promise = new Promise<string[] | null>( async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(null);
            }

            url += "/wp-json/dokanpaymentmethods/v1/get-payment-methods"

            let post_data = {
                vendor_id: vendorId
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        resolve(myJSON.payment_methods);
                    })
                    .catch(error => {
                        resolve(null);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }
        });

        return await promise;
    }

    async function addPaymentMethodToVendor(vendorId: number, paymentMethodId: string):
        Promise<boolean> {

        let promise = new Promise<boolean>( async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            url += "/wp-json/dokanpaymentmethods/v1/add-payment-method"

            let post_data = {
                vendor_id: vendorId,
                payment_method: paymentMethodId,
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        resolve(true);
                    })
                    .catch(error => {
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise;
    }

    async function removePaymentMethodFromVendor(vendorId: number, paymentMethodId: string):
        Promise<boolean> {

        let promise = new Promise<boolean>( async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            url += "/wp-json/dokanpaymentmethods/v1/remove-payment-method"

            let post_data = {
                vendor_id: vendorId,
                payment_method: paymentMethodId,
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        resolve(true);
                    })
                    .catch(error => {
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise;
    }

    return{
        getSiteEnabledPaymentMethods,
        getVendorId,
        getVendorPaymentMethods,
        addPaymentMethodToVendor,
        removePaymentMethodFromVendor,
    };
}
