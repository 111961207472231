import {useErrorHandling} from './useErrorHandling';

const LOGIN_USERNAME_FIELD_BLANK_KEY = 'BLANK_USERNAME';
const LOGIN_USERNAME_FIELD_BLANK_MESSAGE = 'Username field is blank.';

const LOGIN_PASSWORD_FIELD_BLANK_KEY = 'BLANK_PASSWORD';
const LOGIN_PASSWORD_FIELD_BLANK_MESSAGE = 'Password field is blank.';

const LOGIN_INVALID_CREDENTIALS_KEY = 'INVALID_CREDENTIALS';
const LOGIN_INVALID_CREDENTIALS_MESSAGE = 'Your credentials are invalid.';

const LOGIN_SITE_URL_BLANK_KEY = 'BLANK_SITE_URL';
const LOGIN_SITE_URL_BLANK_MESSAGE = 'You need to select a Site to access';


const LOGIN_UNABLE_TO_STORE_TOKEN_KEY = 'TOKEN_STORAGE_ERROR';
const LOGIN_UNABLE_TO_STORE_TOKEN_MESSAGE =
    'Unable to store login information to local storage';

export const AUTH_CREDENTIALS_EXPIRED_KEY = 'CREDENTIALS EXPIRED';
export const AUTH_CREDENTIALS_EXPIRED_MESSAGE = 'Your login has expired.';

export function useAuthentication () {

    const {errorMessages, addErrorMessage, clearErrorMessages} = useErrorHandling();

    const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token';
    const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRATION_TIME = 'expiration_time';

    const LOCAL_STORAGE_SITE_URL = 'site_url';

    const LOGIN_ENDPOINT = 'wp-json/api-bearer-auth/v1/login';

    /**
     * Attempts to log the user into the application.
     *
     * On a successful login, this function will store the user's access and
     * refresh token in local storage, where it can be retrieved in other pages
     * of the application and retrieved when opening the application up again.
     *
     * On an unsuccessful login attempt, this function will set the
     * authErrorMessage to the error message returned by the "API Bearer Auth"
     * plugin.
     *
     * @param username - The email address of the WooCommerce user.
     * @param password - The password for the WooCommerce user.
     * @return - True if the user is successfully authenticated. Otherwise, false.
     */
    const authenticateUser = async (username: string, password: string):
        Promise<boolean> => {
        let promise = new Promise<boolean>((resolve) => {

            const site_url = fetchSiteUrl();

            if(site_url === "") {
                addErrorMessage(LOGIN_SITE_URL_BLANK_KEY, LOGIN_SITE_URL_BLANK_MESSAGE);
                resolve(false);
            }

            const login_url = site_url + LOGIN_ENDPOINT;
            const login_body = {
                username: username,
                password: password,
            };

            fetch(login_url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(login_body),
            })
                .then(response => response.json())
                .then(async myJSON => {
                    if ('code' in myJSON) {
                        // TODO: Handle Clearing invalid Error Messages.
                        if (myJSON.code === 'empty_username') {
                            addErrorMessage(LOGIN_USERNAME_FIELD_BLANK_KEY,
                                LOGIN_USERNAME_FIELD_BLANK_MESSAGE);
                        }
                        else if (myJSON.code === 'empty_password') {
                            addErrorMessage(LOGIN_PASSWORD_FIELD_BLANK_KEY,
                                LOGIN_PASSWORD_FIELD_BLANK_MESSAGE);
                        }
                        else if (myJSON.code === 'invalid_username' ||
                        myJSON.code === 'incorrect_password') {
                            addErrorMessage(LOGIN_INVALID_CREDENTIALS_KEY,
                                LOGIN_INVALID_CREDENTIALS_MESSAGE);
                        }
                        resolve(false);
                    } else {
                        try {
                            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN,
                                myJSON.access_token);

                            let expiration_time: number =
                                Math.round(Date.now() / 1000) + myJSON.expires_in;
                            localStorage.setItem(
                                LOCAL_STORAGE_ACCESS_TOKEN_EXPIRATION_TIME,
                                expiration_time.toString());
                            clearErrorMessages();
                            resolve(true);
                        } catch (e) {
                            addErrorMessage(LOGIN_UNABLE_TO_STORE_TOKEN_KEY,
                                LOGIN_UNABLE_TO_STORE_TOKEN_MESSAGE);
                            resolve(false);
                        }
                    }
                });
        });

        return await promise;
    };

    const checkCredentials = async (): Promise<boolean> => {
        let promise = new Promise<boolean>(async (resolve) => {
            // Load the Expiration Time from Local Storage.
            let expiration_time = localStorage.getItem(
                LOCAL_STORAGE_ACCESS_TOKEN_EXPIRATION_TIME);

            // Check that the Credentials are still valid.
            if (expiration_time !== null) {
                let expiration_time_num: number = parseInt(expiration_time,
                    10);
                let current_time = Math.round(Date.now() / 1000);

                if (current_time < expiration_time_num) {
                    // Load the Credentials from Local Storage.
                    let access_token = localStorage.getItem(
                        LOCAL_STORAGE_ACCESS_TOKEN);

                    if (access_token !== null) {
                        resolve(true);
                    } else { // Something has gone wrong. If expiration time was not null, access token shouldn't be either.
                        resolve(false);
                    }
                } else {
                    // Drop the stored expiration time and access token.
                    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
                    localStorage.removeItem(
                        LOCAL_STORAGE_ACCESS_TOKEN_EXPIRATION_TIME);
                    resolve(false);
                }

            } else { // no Credentials exist in local storage.
                resolve(false);
            }
        });

        return await promise;
    };

    /**
     * Retrieve the credentials from the local storage.
     *
     * If the credentials are no longer valid (i.e. expired), then they will be
     * dropped from local storage. It is the responsibility of the calling
     * function to redirect the application to the login page.
     *
     * @return - A string with the access token if the stored credentials are
     *           still valid. Otherwise, returns null.
     */
    const fetchCredentials = async (): Promise<string | null> => {
        let promise = new Promise<string | null>(async (resolve) => {
            checkCredentials()
                .then(async validCredentialsExist => {
                    if (validCredentialsExist) {
                        let access_token = localStorage.getItem(
                            LOCAL_STORAGE_ACCESS_TOKEN);
                        if (access_token !== null) {
                            resolve(access_token);
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                });
        });
        return await promise;
    };

    /**
     * Logs the user out of the application.
     */
    const deauthenticateUser = () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRATION_TIME);
    }

    const setSiteUrl = (url: string) => {
        localStorage.setItem(LOCAL_STORAGE_SITE_URL, url);
    }

    const fetchSiteUrl = (): string => {
        let url =  localStorage.getItem(LOCAL_STORAGE_SITE_URL);
        if(url !== null) {
            return url;
        } else {
            return "";
        }
    }

    return {
        authErrorMessages: errorMessages,
        authClearErrorMessages: clearErrorMessages,
        authenticateUser,
        checkCredentials,
        fetchCredentials,
        deauthenticateUser,
        setSiteUrl,
        fetchSiteUrl,
    };
}
