import {useAuthentication,
    AUTH_CREDENTIALS_EXPIRED_KEY,
    AUTH_CREDENTIALS_EXPIRED_MESSAGE
} from "./useAuthentication";
import {useErrorHandling} from "./useErrorHandling";

export interface Time {
    hours: number,
    minutes: number,
    seconds: number,
    nanos: number
}

export interface Timeslot {
    ID: number,
    Published: boolean,
    StartTime: Time,
    EndTime: Time,
    DaysOfWeekFlag: string,
    LeadTimeMinutes: number,
    ServiceID: number
    OrderCap: number,
}

export interface ServiceType {
    ID: number,
    Name: string,
    Published: boolean,
}

export interface Location {
    ID: number,
    Name: string,
    ReadOnlyServiceIDs: number[],
}

export interface LocationService {
    Service: ServiceType,
    MinLeadDays: number,
}

export let DaysOfWeekFlags = ["Sunday", "Monday", "Tuesday", "Wednesday",
    "Thursday", "Friday", "Saturday"]

export function useTimeslotsApi() {
    const {fetchCredentials, fetchSiteUrl} = useAuthentication();
    const {errorMessages, checkForResponseErrors, addErrorMessage,
        clearErrorMessages} = useErrorHandling();

    const ADMIN_NAMESPACE = "wp-json/ordertimeslots/v1/adminsvc/";
    const STANDARD_NAMESPACE = "wp-json/ordertimeslots/v1/svc/"

    /**
     * Get the Locations associated with the current site.
     */
    async function getLocations(): Promise<Location[] | null> {
        let promise = new Promise<Location[] | null>(async (resolve) => {
            let url: string = fetchSiteUrl();
            if(url === "") {
                resolve(null);
            }

            url += ADMIN_NAMESPACE + "get-locations"

            let credentials: string | null = await fetchCredentials();

            if(credentials !== null) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(myJSON => {
                        let locations: Location[] = [];
                        for(let i = 0; i < myJSON.Locations.length; i++) {
                            locations.push({
                                ID: myJSON.Locations[i].ID,
                                Name: myJSON.Locations[i].Name,
                                ReadOnlyServiceIDs: myJSON.Locations[i].ReadOnlyServiceIDs,
                            })
                        }
                        resolve(locations);
                    });
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }

        });
        return await promise;
    }

    /**
     * Generates a collection of Timeslots.
     *
     * @param startHour
     * @param startMinute
     * @param endHour
     * @param endMinute
     * @param timeslotSize
     * @param leadTime
     * @param serviceId
     * @param orderCap
     * @param currentDay
     */
    function generateTimeslots(startHour: number, startMinute: number, endHour: number,
                               endMinute: number, timeslotSize: number, leadTime: number,
                               serviceId: number, orderCap: number, currentDay: string): Timeslot[] {

        let currentHour = startHour;
        let currentMinute = startMinute;
        let newTimeslots: Timeslot[] = [];
        while(!(currentHour === endHour && currentMinute >= endMinute)) {

            // Create a Timeslot for the current Time
            let startTime: Time = {
                hours: currentHour,
                minutes: currentMinute,
                seconds: 0,
                nanos: 0
            }

            currentMinute += timeslotSize;

            // Check if slot's endTime exceeds the final endTime
            if(currentHour === endHour && currentMinute > endMinute) {
                continue;
            }

            let endTime: Time = {
                hours: currentHour,
                minutes: currentMinute - 1,
                seconds: 59,
                nanos: 999
            }

            let newTimeslot: Timeslot = {
                ID: 0,
                Published: true,
                StartTime: startTime,
                EndTime: endTime,
                DaysOfWeekFlag: currentDay,
                LeadTimeMinutes: leadTime,
                ServiceID: serviceId,
                OrderCap: orderCap,
            }

            if(currentMinute >= 60) {
                currentMinute -= 60;
                currentHour += 1;
                if(currentHour > 23) {
                    currentHour = 0;

                    // Get the next Day.
                    let currentDayIndex = DaysOfWeekFlags.indexOf(currentDay);
                    currentDay = DaysOfWeekFlags[(currentDayIndex + 1) % 7];
                }
            }

            newTimeslots.push(newTimeslot);
        }

        return newTimeslots;
    }

    /**
     * Sorts the Timeslots returned from the
     *
     * @param array
     */
    function sortTimeslotArray(array: Timeslot[]) {
        return array.sort((obj1, obj2) => {
            if((obj1.StartTime.hours * 60 + obj1.StartTime.minutes) >
            (obj2.StartTime.hours * 60 + obj2.StartTime.minutes)) {
                return 1;
            }

            if((obj1.StartTime.hours * 60 + obj1.StartTime.minutes) <
                (obj2.StartTime.hours * 60 + obj2.StartTime.minutes)) {
                return -1;
            }

            return 0;
        })
    }

    /**
     * Calls the get-timeslots admin endpoint from the Timeslot Plugin.
     *
     * This function returns the Timeslots as a 2d array. Each array within
     * the outer array contains the Timeslots for a single day of the week.
     *
     * @param locationId
     * @param serviceTypeId
     * @return An array of 7 Timeslot arrays, one for each day of the week.
     * If there's an error, it returns an empty array.
     */
    async function getTimeslots(locationId: number, serviceTypeId: number):
        Promise<Timeslot[][]> {

        let promise = new Promise<Timeslot[][]>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve([]);
            }

            let post_data = {
                LocationID: locationId,
                ServiceTypeIDs: [serviceTypeId]
            }

            let credentials = await fetchCredentials();

            if (credentials !== null) {
                let timeSlots: Timeslot[][] = [[], [], [], [], [], [], []];
                fetch(url + ADMIN_NAMESPACE + "get-timeslots", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve([]);
                        } else {
                            myJSON.TimeSlots.forEach((value:Timeslot) => {
                                switch (value.DaysOfWeekFlag) {
                                    case "Sunday":
                                        timeSlots[0].push(value);
                                        break;
                                    case "Monday":
                                        timeSlots[1].push(value);
                                        break;
                                    case "Tuesday":
                                        timeSlots[2].push(value);
                                        break;
                                    case "Wednesday":
                                        timeSlots[3].push(value);
                                        break;
                                    case "Thursday":
                                        timeSlots[4].push(value);
                                        break;
                                    case "Friday":
                                        timeSlots[5].push(value);
                                        break;
                                    case "Saturday":
                                        timeSlots[6].push(value);
                                        break;
                                }
                            });

                            timeSlots.forEach((array, index) => {
                                timeSlots[index] = sortTimeslotArray(array);
                            })

                            resolve(timeSlots);
                        }
                    })
                    .catch(error => {

                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve([]);
            }
        });

        return await promise;
    }

    /**
     * Calls the add-update-timeslots endpoint from the Timeslot Plugin.
     * @param locationId
     * @param timeslots
     * @return true if the request was successful. Otherwise, false.
     */
    async function addUpdateTimeslots(locationId: number, timeslots: Timeslot[]):
        Promise<boolean> {
        let promise = new Promise<boolean>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            let post_data = {
                LocationID: locationId,
                TimeSlots: timeslots
            }

            let credentials = await fetchCredentials();

            if (credentials !== null) {
                fetch(url + ADMIN_NAMESPACE + "add-update-timeslots", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(false);
                        } else {
                            clearErrorMessages();
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise;
    }

    async function deleteTimeslots(locationId: number, slotIds: number[]):
        Promise<boolean> {

        let promise = new Promise<boolean>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            let post_data = {
                LocationID: locationId,
                SlotIDs: slotIds
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url + ADMIN_NAMESPACE + "delete-timeslots", {
                    method: "DELETE",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(false);
                        } else {
                            clearErrorMessages();
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise;
    }

    /**
     * Calls the adminsvc/get-services endpoint from the Timeslot Plugin.
     */
    async function getAllServices(): Promise<ServiceType[] | null> {
        let promise = new Promise<ServiceType[] | null>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(null);
            }

            let post_data = {
                ServiceID: [],
            }

            let credentials = await fetchCredentials();
            if(credentials !== null) {
                fetch(url + ADMIN_NAMESPACE + "get-services", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(null);
                        } else {
                            let services: ServiceType[] = [];
                            myJSON.Services.forEach((service:ServiceType) => {
                                services.push(service);
                            })
                            resolve(services);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(null);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }
        });

        return await promise;
    }

    /**
     * Calls the svc/get-services endpoint from the Timeslot Plugin.
     * @param locationId - The location to fetch services for.
     */
    async function getServicesForLocation(locationId: number):
        Promise<LocationService[] | null> {

        let promise = new Promise<LocationService[] | null>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(null);
            }

            let post_data = {
                LocationID: locationId,
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url + STANDARD_NAMESPACE + "get-services", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(null);
                        } else {
                            let services: LocationService[] = [];
                            myJSON.Services.forEach((service:LocationService) => {
                                services.push(service);
                            })
                            resolve(services);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(null);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(null);
            }
        });

        return await promise
    }

    /**
     * Calls the adminsvc/add-services-to-location endpoint from the Timeslot Plugin.
     * @param locationId - The location to add the Service to.
     * @param serviceTypeId - The ID of the Service to add.
     * @param minLeadDays - The number of days ahead that the Location will
     *                      accept orders for that Service.
     */
    async function addServiceTypesToLocation(locationId: number, serviceTypeId: number,
                                             minLeadDays: number): Promise<boolean> {

        let promise = new Promise<boolean>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            let post_data = {
                LocationID: locationId,
                ServiceID: [serviceTypeId],
                MinLeadDays: minLeadDays,
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url + ADMIN_NAMESPACE + "add-services-to-location", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise
    }

    /**
     * Calls the adminsvc/remove-services-from-locationq endpoint from the Timeslot Plugin.
     * @param locationId - The location to add the Service to.
     * @param serviceTypeId - The ID of the Service to add.
     */
    async function removeServicesFromLocation(locationId: number, serviceTypeId: number,):
        Promise<boolean> {

        let promise = new Promise<boolean>(async (resolve) => {
            let url: string = fetchSiteUrl();

            if(url === "") {
                resolve(false);
            }

            let post_data = {
                LocationID: locationId,
                ServiceID: [serviceTypeId],
            }

            let credentials = await fetchCredentials();

            if(credentials !== null) {
                fetch(url + ADMIN_NAMESPACE + "remove-services-from-location", {
                    method: "POST",
                    headers: new Headers({
                        'Authorization': 'Bearer ' + credentials,
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(post_data)
                })
                    .then(response => checkForResponseErrors(response))
                    .then(response => response.json())
                    .then(myJSON => {
                        if(myJSON.ErrorMsg !== "") {
                            addErrorMessage("TIMESLOT_ERROR", myJSON.ErrorMsg);
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        let message = "Unable to submit request. " + error.toString();
                        addErrorMessage("TIMESLOT_ERROR", message);
                        resolve(false);
                    })
            } else {
                addErrorMessage(AUTH_CREDENTIALS_EXPIRED_KEY,
                    AUTH_CREDENTIALS_EXPIRED_MESSAGE);
                resolve(false);
            }
        });

        return await promise
    }

    return {
        getLocations,
        generateTimeslots,
        getTimeslots,
        addUpdateTimeslots,
        deleteTimeslots,
        getAllServices,
        getServicesForLocation,
        addServiceTypesToLocation,
        removeServicesFromLocation,

        timeslotErrorMessages: errorMessages,
        timeslotClearErrorMessages: clearErrorMessages,
    };
}
