import React, {useEffect} from "react";
import {Button} from "react-bootstrap";
import {useAuthentication} from "../hooks/useAuthentication";
import {useHistory} from "react-router-dom";

import "./PageHeader.css"

interface Props {
    pageTitle: string
}

const PageHeader: React.FC<Props> = (props:Props) => {
    const {checkCredentials, deauthenticateUser} = useAuthentication();
    let history = useHistory();

    useEffect(() => {
        checkCredentials().then((credentialsAreValid) => {
            if(!credentialsAreValid) {
                history.push("/");
            }
        })
    })

    return (
        <div className={"page-header"}>
            <h2>{props.pageTitle}</h2>
            <Button onClick={() => {
                deauthenticateUser();
                history.push("/");
            }}>
                Log Out
            </Button>
        </div>
    );
}

export default PageHeader
