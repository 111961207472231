import React, {useEffect, useState} from "react";
import {ErrorMessage} from "../hooks/useErrorHandling";
import {Alert} from "react-bootstrap";

import "./ErrorDisplay.css"

interface Props {
    errors: ErrorMessage[],
    clearErrors: () => void,
}

const ErrorDisplay: React.FC<Props> = (props: Props) => {

    const [showErrors, setShowErrors] = useState<boolean>(false);

    useEffect(() => {
        if(props.errors.length > 0) {
            setShowErrors(true);
        } else {
            setShowErrors(false);
        }
    }, [props.errors])

    return(
        <div className={"error-display"}>
            {showErrors &&
            <Alert
                variant={"danger"}
                onClose={() => {
                    props.clearErrors();
                    setShowErrors(false);
                }}
                dismissible={true}
            >
                <ul>
                    {props.errors.map((error) => {
                        return <li>{error.message}</li>
                    })}
                </ul>
            </Alert>
            }
        </div>
    );
}

export default ErrorDisplay
